import { render, staticRenderFns } from "./LanguageSelector.vue?vue&type=template&id=0262267e&scoped=true&"
import script from "./LanguageSelector.vue?vue&type=script&lang=js&"
export * from "./LanguageSelector.vue?vue&type=script&lang=js&"
import style0 from "@/assets/less/components/language-selector.less?vue&type=style&index=0&id=0262267e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0262267e",
  null
  
)

export default component.exports